const firebaseConfig = {
    apiKey: "AIzaSyA7HFGP_a8SwQd6DYXP6hBcFHm6rBHI_ek",
    authDomain: "araiapp.firebaseapp.com",
    projectId: "araiapp",
    storageBucket: "araiapp.appspot.com",
    messagingSenderId: "560620256673",
    appId: "1:560620256673:web:c3886d9a102209c79fdfe0",
    measurementId: "G-NE4MP5WTP2"
};

export default firebaseConfig;